.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background {
  width: 100%;
  height: 100%;
  position: relative;
}
.img-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dbeafec2;
  padding: 30px;
  position: absolute;
  border-radius: 10px;
}
.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  background-color: #4285f4;
}