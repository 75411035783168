        body {
            background-color: #fff;
            color: #333;
            font: 1.2em / 1.5 Helvetica Neue, Helvetica, Arial, sans-serif;
            
        }

        .mainnavradio {
            width: 400px;
            background-color: #fff;
            display: flex;
           
          
            list-style: none;
            height:  50px;
        }
        .mainnavradioParent {
            width: 1064px;
            background-color: #fff;
             display: block;
            margin-left: auto;
            margin-right: auto;
 
          
            list-style: none;
            height:  50px;
                text-align: center;
 
            
            border-radius: 5px;
            align-items: center;
            justify-content: center;
        }

        .mainnavradio a {
            background-color: #fff;
            padding: .5em 1em;
            display: block;
        }

        .left {
            margin-left: auto;
        }
         .right {
            margin-right:  auto;
        }