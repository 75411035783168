.bookcase {
    background: #fff;
    font-size: 20px;
}
.categories-bookcase {
    display: flex;
}
.category-item {
    padding: 30px;
    border: 1px #ddd solid;
    border-radius: 10px;
    cursor: pointer;
}
.book-item {
    display: flex;
}
.book-item-image {
    width: 20%;
}
.book-item-content {
    width: 79%;
}
.book-item-content ul {
    list-style: none;
}
.book-item-content ul li {
    text-align: left;
    padding: 5px 0;
}
.book-item-content .button-remove {
    background: #ccc;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
.selected {
    background: #ccc;
}