/* Create a top navigation bar with a black background color  */
.topnavNavigation {
  background-color: #00BFFF;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnavNavigation a {
  float: left;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnavNavigation a:hover {
  background-color: #ddd;
  color: black;
}

/* Create a right-aligned (split) link inside the navigation bar */
.topnavNavigation a.split {
  float: right;
 
  color: 000000;
}
