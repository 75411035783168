* {box-sizing: border-box;}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.seach_main  {
  overflow: hidden;
  background-color: #F8F8F8;

 
}

.seach_main a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.seach_main a:hover {
  background-color: #ddd;
  color: black;
}

.seach_main a.active {
  background-color: #2196F3;
  color: white;
}

.seach_main .search-container {
  float: right;
  display: flex;
}

.seach_main input[type=text] {
  padding: 12px;
  margin-top: 1px;
  font-size: 17px;
  border: none;
}

.seach_main .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.seach_main .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .seach_main .search-container {
    float: none;
  }
  .seach_main a, .seach_main input[type=text], .seach_main .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .seach_main input[type=text] {
    border: 1px solid #ccc;
  }
}
.anticon-search svg {
  margin-top: 6px;
}