.App {
  font-family: sans-serif;
  text-align: center;
}
.flex-container {
  display: flex;
  flex-flow: row wrap;
}
.flex-container a {
  text-decoration: none;
  color: black;
}
.flex-container .element-item {
  width: 33%;
}
.content-element-list {
  border: 2px #ddd solid;
  border-radius: 5px;
  margin: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.quiz-image {
  background: #ccc;
  width: 20%;
}
.quiz-item {
  width: 79%;
  text-align: left;
  padding-left: 20px;
}