.Header { grid-area: header; }
.Left { grid-area: menu; }
.Main { grid-area: main; }
.Right { grid-area: right; }
.Footer { grid-area: footer; }

.grid-container {
  display: grid;
  grid:
  'header   header  header  header  header      header      header      header      header'
  'menu     main    main    main    main        main        main        main        right'
  'menu     footer  footer  footer  footer      footer      footer      footer      footer';
  grid-gap: 1px;
  background-color: #2196F3;
  padding: 1px;
}

.grid-container > div {
  background-color: #ffffffcc;
  text-align: center !important;
  padding: 2px 0;
  font-size: 30px;
}