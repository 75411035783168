body {
  font-family: Lato, sans-serif;
}

section {
  padding: 1rem;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(#000, 0.1);
  border-radius: 5px;

  h2 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.images-list {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x;
  //scroll-padding: 1rem;

  img {
    scroll-snap-align: center;
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
    border-radius: 5px;
  }
}

.wrapper {

    max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
}

.form-style-8{
	width:100%;
	padding:30px;
	margin:40px auto;
	background: #FFF;
	border-radius: 10px;
	-webkit-border-radius:10px;
	-moz-border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);

}
.form-style-8 h2{
	background: #2A88AD;
	padding: 20px 30px 15px 30px;
	margin: -30px -30px 30px -30px;
	border-radius: 10px 10px 0 0;
	-webkit-border-radius: 10px 10px 0 0;
	-moz-border-radius: 10px 10px 0 0;
	color: #fff;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
	font: normal 30px 'Bitter', serif;
	-moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
	-webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
	box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
	border: 1px solid #257C9E;
}