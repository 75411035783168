        body {

            background: #FFF;
            color: #333;
            font: 1.2em / 2.5 Helvetica Neue, Helvetica, Arial, sans-serif;
            padding: 0;
            margin: 0;
        }

        .main-nav {


            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            height:  50px;
        }

        .main-nav a {
            background-color: #00BFFF;
            padding: .5em .1em;
            display: block;
        }

        .left {
            margin-left: auto;
        }
         .right {
            margin-right:  auto;
        }